<template>
  <div class="admin-add-user">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="user"
      :handleUpdate="({ key }, v) => (user[key] = v)"
    />
  </div>
</template>

<script>
import userSchema from "@/validation-schemas/user";
import FormGenerator from "@/components/form-generator.vue";
import roles from "@/fixtures/roles";

export default {
  components: { FormGenerator },
  name: "admin-add-user",
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        role: "client"
      },
      loading: false,
      error: null
    };
  },
  computed: {
    elements() {
      return [
        {
          key: "name",
          label: "name",
          type: "string",
          required: true
        },
        {
          key: "email",
          label: "email",
          type: "string",
          required: true
        },
        {
          key: "password",
          label: "password",
          type: "password",
          required: true
        },
        {
          key: "role",
          label: "role",
          type: "select",
          attrs: {
            options: roles.map(s => ({
              text: this.$t(s),
              value: s
            }))
          }
        },
        {
          text: "add-user",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.add
          }
        }
      ];
    }
  },
  methods: {
    async add() {
      this.error = null;
      const { user } = this;
      const validation = userSchema.validate(user, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch("users/create", user);
          this.$emit("added");
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
